// extracted by mini-css-extract-plugin
var _1 = "global_controls__RMtCf";
var _2 = "global_controlsCenter__QXTq6";
var _3 = "global_controlsSpaceBetween__J3gFW";
var _4 = "global_cursorDefault__+5q9o";
var _5 = "global_cursorGrab__zL2fy";
var _6 = "global_cursorPointer__pLmcu";
var _7 = "global_scrollableX__Xwcd-";
var _8 = "global_scrollableXY__MslcX";
var _9 = "global_scrollableY__X98fs";
export { _1 as "controls", _2 as "controlsCenter", _3 as "controlsSpaceBetween", _4 as "cursorDefault", _5 as "cursorGrab", _6 as "cursorPointer", _7 as "scrollableX", _8 as "scrollableXY", _9 as "scrollableY" }
