// extracted by mini-css-extract-plugin
var _1 = "Card_attachment__KnwuL";
var _2 = "Card_attachmentLeft__jEyOp";
var _3 = "Card_attachments__-CgZV";
var _4 = "Card_attachmentsRight__Jazpc";
var _5 = "Card_card__zQfcO";
var _6 = "Card_cardOpen__zS98C";
var _7 = "Card_content__ASKh8";
var _8 = "Card_cover__Ss36Q";
var _9 = "Card_details__wrmtl";
var _a = "Card_detailsIcon__wCvfX";
var _b = "Card_detailsNotification__6wbTI";
var _c = "Card_detailsTitle__IsX6U";
var _d = "Card_editCardButton__0U0o1";
var _e = "Card_labels__qJe5L";
var _f = "Card_moreUsers__-BQQr";
var _10 = "Card_name__vpWb5";
var _11 = "Card_notification__eR7Sh";
var _12 = "Card_notificationFull__JcBBl";
var _13 = "Card_popupWrapper__3a-YJ";
var _14 = "Card_popupWrapper2__pqt-t";
var _15 = "Card_target__GXNbE";
var _16 = "Card_user__MeExM";
var _17 = "Card_users__lqZz3";
var _18 = "Card_wrapper__kLQpI";
export { _1 as "attachment", _2 as "attachmentLeft", _3 as "attachments", _4 as "attachmentsRight", _5 as "card", _6 as "cardOpen", _7 as "content", _8 as "cover", _9 as "details", _a as "detailsIcon", _b as "detailsNotification", _c as "detailsTitle", _d as "editCardButton", _e as "labels", _f as "moreUsers", _10 as "name", _11 as "notification", _12 as "notificationFull", _13 as "popupWrapper", _14 as "popupWrapper2", _15 as "target", _16 as "user", _17 as "users", _18 as "wrapper" }
