// extracted by mini-css-extract-plugin
var _1 = "BoardActions_action__4ShVr";
var _2 = "BoardActions_actionRightFirst__M9cxM";
var _3 = "BoardActions_actionRightLast__sLNN5";
var _4 = "BoardActions_cardsCount__xFf8S";
var _5 = "BoardActions_githubAction__MbwzQ";
var _6 = "BoardActions_githubGreen__HjN62";
var _7 = "BoardActions_githubGrey__8XjmR";
var _8 = "BoardActions_title__jMP-z";
var _9 = "BoardActions_wrapper__rxxRn";
export { _1 as "action", _2 as "actionRightFirst", _3 as "actionRightLast", _4 as "cardsCount", _5 as "githubAction", _6 as "githubGreen", _7 as "githubGrey", _8 as "title", _9 as "wrapper" }
